document.addEventListener('DOMContentLoaded', function () {
  let width = 0
  let timeout = 0
  const delay = 250

  let launchHeader = document.querySelector('.ap-launchHeader')
  let frames = document.querySelectorAll('.ap-launchHeader div')
  let frameCount = 7

  let body = document.querySelector('body')
  let menu = document.getElementById('masthead')
  let header = document.getElementById('page-header')
  let box = document.querySelector('.box-wrapper')

  function logoAnimate () {
    let menuH = menu.clientHeight
    let headerH = header.clientHeight
    let totalH = menuH + headerH
    let frameRate = totalH / (frameCount)

    if (window.scrollY <= totalH) {
      box.style.position = 'fixed'
      box.style.top = '0'
      body.style.height = '5000px'
      body.style.paddingTop = window.scrollY + 'px'
      let setFrame = Math.floor(window.scrollY / frameRate)

      frames.forEach(function (frame, i) {
        frame.style.display = (i === setFrame) ? 'block' : 'none'
      })
    } else {
      body.style.height = 'auto'
      box.style.position = 'static'
      box.style.top = 'auto'

      frames.forEach(function (frame, i) {
        if (i === frames.length - 1) {
          frame.style.display = 'block'
        }
      })
    }
  }

  function showFinal () {
    frames.forEach(function (frame, i) {
      frame.style.display = (i === frames.length - 1) ? 'block' : 'none'
    })
    body.style.height = 'auto'
    body.style.paddingTop = '0'
    box.style.position = 'static'
    box.style.top = 'auto'
    window.onscroll = null
  }

  function getWidth () {
    width = window.innerWidth
    window.onscroll = null
    if (width >= 960) {
      window.onscroll = logoAnimate
      logoAnimate()
    } else {
      showFinal()
    }
  }

  if (launchHeader) {
    window.scrollTo(0, 0)
    window.addEventListener('resize', function () {
      clearTimeout(timeout)
      timeout = setTimeout(getWidth, delay)
      getWidth()
    })
    getWidth()
  }
})
