document.addEventListener('DOMContentLoaded', function () {
  let homeLogo = document.querySelector('.ap-header--home img')
  if (homeLogo) {
    let homeLogoSrc = homeLogo.getAttribute('src')

    if (homeLogoSrc.includes('.gif')) {
      homeLogo.setAttribute('src', homeLogoSrc + '?' + Math.random())
    }
    homeLogo.style.opacity = '1'
  }

  // Toggle Main Menu
  let body = document.querySelector('body')
  let menuToggle = document.querySelector('.ap-menu-toggle')
  let mainMenu = document.querySelector('.ap-menu_secondary')

  function openMenu (e) {
    e.preventDefault()
    e.stopPropagation()
    mainMenu.classList.add('ap-active')
    body.classList.add('ap-menuOpen')
    if (!mainMenu.classList.contains('ap-subsFixed')) {
      // Remove mouseenter event on menu items
      let hasSubMenu = document.querySelectorAll('.ap-menu_secondary .menu-item-has-children')

      for (let i = 0; i < hasSubMenu.length; i++) {
        let oldMenu = hasSubMenu[i]
        let newMenu = oldMenu.cloneNode(true)
        let submenu = newMenu.querySelector('ul')

        let subToggle = document.createElement('a')
        subToggle.title = 'open submenu'
        subToggle.className = 'ap-submenu-toggle'
        subToggle.href = '#'

        subToggle.addEventListener('click', (e) => {
          e.preventDefault()
          newMenu.classList.toggle('ap-active')
        })

        newMenu.insertBefore(subToggle, submenu)
        oldMenu.parentNode.replaceChild(newMenu, oldMenu)
      }
      mainMenu.classList.add('ap-subsFixed')
    }
  }

  function closeMenu (e) {
    if (e) e.preventDefault()
    mainMenu.classList.remove('ap-active')
    body.classList.remove('ap-menuOpen')
  }

  if (menuToggle && mainMenu) {
    let closeBtn = document.createElement('a')
    closeBtn.text = 'close'
    closeBtn.className = 'ap-close'
    closeBtn.href = '#'

    mainMenu.insertBefore(closeBtn, mainMenu.firstChild)
    mainMenu.addEventListener('click', (e) => e.stopPropagation())
    menuToggle.addEventListener('click', (e) => openMenu(e))
    closeBtn.addEventListener('click', (e) => closeMenu(e))
    document.addEventListener('click', () => {
      if (mainMenu.classList.contains('ap-active')) { closeMenu() }
    })
  }

  // Toggle Hero Image Caption
  let heroCaption = document.querySelector('.ap-hero_caption-wrap')

  if (heroCaption) {
    let heroCaptionBtn = heroCaption.querySelector('.ap-hero_caption-btn a')
    let heroCaptionText = heroCaption.querySelector('.ap-hero_caption')

    heroCaptionBtn.addEventListener('click', (e) => {
      e.preventDefault()
      heroCaptionBtn.classList.toggle('ap-active')
      heroCaptionText.classList.toggle('ap-active')
    })
  }
})
